import React, {Component} from "react";
import Layout from "../../components/common/layout";
import Content from "../../components/common/content";
import UrlService from "../../services/UrlService";
import HttpService from "../../services/HttpService";

import FileUploadComponent from "../../components/common/fileupload";
import loadingGif from "../../assets/img/loading.gif";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import {AllCommunityModules} from 'ag-grid-community';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import Modal from "../../components/common/modal";
import CompanyAdd from "./company_add.tsx";
import Audit from "../../components/common/audit";
import Contact from "../../components/common/contact";
import Address from "../../components/common/address";
import CompanyCard from "../../components/companycard";
import MassSMS from "../../components/masssms";
import MassEmail from "../../components/massemail";
import DeleteRenderer from "../../aggrid/DeleteRenderer.jsx";
import MainTableColumnDefs from "../../aggrid/MainTableColumnDefs.jsx";
import SessionStorageService from "../../services/SessionStorageService";
import DatePicker from "../../aggrid/DatePicker.js";
import DateTimePicker from "../../aggrid/DateTimePicker.jsx";

import MultiSelectSimple from "../../aggrid/MultiSelectSimple.jsx";
import MultiSelectLargeData from "../../aggrid/MultiSelectLargeData.jsx";
import ContactEditor from "../../aggrid/ContactEditor.jsx";

import LocaleEn from "../../aggrid/locale.en.js";
import LocaleCs from "../../aggrid/locale.cs.js";

import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';
import Loading from "../../components/common/loading";
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Pusher from 'react-pusher';

import AgGridState from "../../components/common/ag_grid_state";

import {LicenseManager} from "ag-grid-enterprise";
//import {LoadState, SaveState} from "../../services/GridStateService";
//LicenseManager.setLicenseKey("For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-15_April_2021_[v2]_MTYxODQ0MTIwMDAwMA==67a27e14193824f724b8ea4900ebfe26");
LicenseManager.setLicenseKey("CompanyName=GTS - Global Tungsten Solution s.r.o.,LicensedGroup=GTSDB,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-015194,ExpiryDate=23_May_2022_[v2]_MTY1MzI2MDQwMDAwMA==d202e751d68f01012278c38da399e166");


interface Props {
	onUpdateYear: (data) => void;
	onUpdateMonth: (data) => void;
	onUpdateContinent: (data) => void;
	onUpdateCountry: (data) => void;
};


class Companies extends Component<Props> {

    constructor(props) {
        var locale = 'cs';
        if (localStorage.getItem('locale') != null) locale = localStorage.getItem('locale');
        var loctext;
        if (locale == 'cs') loctext = LocaleCs;
        if (locale == 'en') loctext = LocaleEn;
        const customComparator = (valueA, valueB) => {
			
			console.log( valueA, valueB );
			
            if (valueA != null && valueB != null)
                return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        };


        super(props)
        //console.log(props);
        this.AuditElement = React.createRef();
        this.state = {
			locale: locale,
			
			Year: null,
			Month: null,
			Continent: null,
			Country: null,
			
            isColumnStateLoaded: false,
            showLoading: true,
            pages: 0,
            popupParent: document.querySelector('body'),
            showModal: (this.props.opts.action == 'add') ? true : false,
            showModalSMS: false,
            showModalEmail: false,
            showModalSMSAddresses: [],
            showModalEmailAddresses: [],
            showAudit: false,
            showAuditId: null,
            showContact: false,
            showContactNode: null,
            showContactId: null,
            showCompanyCard: false,
            showCompanyCardName: 'Company card',
            showCompanyCardNode: null,
            showCompanyCardId: null,
            showCompanyCardLoadingAddress: null,
            showAddress: false,
            showAddressName: 'Address',
            showAddressNode: null,
            showAddressId: null,
            showAddressType: null,
            modules: AllCommunityModules,
            localeText: loctext,
            rowdata: null,
            columnDefs: MainTableColumnDefs('companies'),
            defaultColDef: {
                editable: false,
                filter: true,
                minWidth: 20,
                resizable: true,
                sortable: true,
                comparator: customComparator,
                filterParams: {
                    textFormatter: function (value) {
                        return value.toLowerCase( )
                            .replace(/\s/g, '')
                            .replace(/[àáâãäå]/g, 'a')
                            .replace(/[ě]/g, 'e')
                            .replace(/[š]/g, 's')
                            .replace(/[č]/g, 'c')
                            .replace(/[ř]/g, 'r')
                            .replace(/[ž]/g, 'z')
                            .replace(/[ů]/g, 'u')
                            .replace(/[ň]/g, 'n')
                            .replace(/æ/g, 'ae')
                            .replace(/ç/g, 'c')
                            .replace(/[èéêë]/g, 'e')
                            .replace(/[ìíîï]/g, 'i')
                            .replace(/ñ/g, 'n')
                            .replace(/[òóôõö]/g, 'o')
                            .replace(/œ/g, 'oe')
                            .replace(/[ùúûü]/g, 'u')
                            .replace(/[ýÿ]/g, 'y')
                            .replace(/\W/g, '');
                    }
                }
            },
            frameworkComponents: {
                DeleteRenderer: DeleteRenderer,
                MultiSelectSimple: MultiSelectSimple,
                MultiSelectLargeData: MultiSelectLargeData,
                ContactEditor: ContactEditor,
                DatePicker: DatePicker,
            },

			AgGridReady: false

        };

        this.pathname = props.location.pathname;

    }

    onColumnsStateChanged(params) {
		
		console.log( "onColumnsStateChanged" );
		
        if (this.state.isColumnStateLoaded) {
            //SaveState('Companies', params.columnApi, params.api);
        }
    }


    componentDidMount() {

        var q = 'companies_count';

        if (this.pathname.indexOf('active_companies') !== false) q = 'active_companies_count';


        HttpService.get(UrlService.apiDomain() + 'api/companies_count')
            .then(res => {

                var pages = Math.ceil(parseInt(res.data[0].count) / 30000);

                this.pages = pages;


                var i = 0;
                for (i = 0; i < pages; i++) {
                    this.loadPartially(i);
                }


            })
            .catch((error) => {
                console.log(error);
            });
    }
	
	componentDidUpdate( prevProps, prevState ) {
		
		console.log( "companies update", this.props, prevProps );
		
		if( 
			( prevState.Year != this.state.Year ) || 
			( prevState.Month != this.state.Month ) 
		){
			
			//this.setFilter( );
			
		}
		
		if( 
			( prevState.Continent != this.state.Continent ) || 
			( prevState.Country != this.state.Country ) 
		){
			
			this.setFilter( );
			
		}
		
		//this.setFilter( );
		
    }
	
	setFilter( ){
		
		console.log( "set filter", this.gridApi, this.props );
		
		let filtr = {
			
		};
		
		//if( this.props.continent != null ) {
			
		if( this.state.Continent != null ){
			
			let continents = JSON.parse( localStorage.getItem( 'continents' ) );
			
			let continent = null;
			
			for( let i in continents ){
				
				if( continents[ i ].id.toString( ) == this.state.Continent.toString( ) ){//this.props.continent.toString( ) ){
					
					continent = continents[ i ][ 'name_' + this.state.locale ];
					
					break;
					
				}
				
			}
			
			filtr = {
				...filtr,
				...{
					continent: {
						type: 'set',
						values: [ continent ]
					}
				}
			};
			
		}
		
		if( this.state.Country != null ){
		//if( this.props.country != null ){
			
			let countries = JSON.parse( localStorage.getItem( 'countries' ) );
			
			let country = null;
			
			for( let i in countries ){
				
				if( countries[ i ].id.toString( ) == this.state.Country.toString( ) ){//this.props.country.toString( ) ){
					
					country = countries[ i ][ 'name_' + this.state.locale ];
					
					break;
					
				}
				
			}

			filtr = {
				...filtr,
				...{
					country: {
						type: 'set',
						values: [ country ]
					}
				}
			};
				
		}
		
		console.log( filtr );
			
		this.gridApi.setFilterModel( filtr );
		
	}

    loadPartially(page) {

        var offset = page * 30000;
        HttpService.get(UrlService.apiDomain() + 'api/companies/offset/' + offset)
            .then(res => {
                let rowData = this.state.rowdata ?? [];
                this.setState({
                    rowdata: rowData.concat(res.data)
                });

                // console.log('G'+this.pages+page);
                if (this.pages == page + 1) {
                    this.setState({
                        showLoading: false
                    });

					setTimeout( this.setFilter.bind( this ), 100 )

                    /*if (localStorage.getItem('country') != null && localStorage.getItem('country') != '') {

                        function tt() {
                            var hardcodedFilter = {
                                country: {
                                    type: 'set',
                                    values: [localStorage.getItem('country_name')],
                                },
                            };
                            console.log(hardcodedFilter);
                            this.gridApi.setFilterModel(hardcodedFilter);
                        }


                        setTimeout(tt.bind(this), 100);


                    }*/


                }
                setTimeout(() => {
                    //LoadState('Companies', this.gridColumnApi, this.gridApi);
                    this.setState({
                        isColumnStateLoaded: true
                    })
                }, 500);

                /*
                 var allColumnIds = [];
                 var skipHeader = false;
                 this.gridColumnApi.getAllColumns().forEach(function (column) {
                 allColumnIds.push(column.colId);
                 });
                 this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
                 */
            })
            .catch((error) => {
                console.log(error);
            });
    }


    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
		

        //LoadState('Companies', params.columnApi, params.api);
		this.setState( {
			AgGridReady: true
		} );
    };

    onCellCValueChanged = (event) => {
        console.log(event);

        event.data.field = event.colDef.field;
        HttpService.put(UrlService.apiDomain() + 'api/companies/' + event.data.id, event.data)
            .then(res => {
                if (res.data == null) alert("Chyba připojení k serveru");
                if (res.data.alert !== '' && typeof (res.data.alert) !== 'undefined') alert(res.data.alert);
                if (res.data.result == 'failed') {
                    alert(res.data.message);

                } else {
                    console.log('Successfully updated');
                    event.node.setData(res.data.entry);

                    if (res.data.made_active == 1) {
                        //   SessionStorageService.remove('companies', res.data.entry.id);
                        SessionStorageService.add('companies', res.data.entry);
                    }

                }
            })
            .catch((error) => {
                alert(error);
                console.log(error);
            })


    }


    onCellClicked = (e) => {
        var that = this;
        var field = e.column.colId;
        if (field === "contact_person" || field === "contact_person_position" || field === "contact_person_note" || field === 'fax' || field === 'phone' || field === 'email') {
            that.setState({
                showContact: true,
                showContactId: e.node.data.id,
                showContactNode: e.node
            });
        }

        if (field === "name") {
            //  console.log( e.node.data.id)
            that.setState({
                showCompanyCard: true,
                showCompanyCardName: e.node.data.name,
                showCompanyCardId: e.node.data.id,
                showCompanyCardLoadingAddress: e.node.data.loading_address,
                showCompanyCardNode: e.node
            });


        }

        if (field === "state" || field === "city" || field === "zip" || field === "street" || field === "house" || field === "lattitude" || field === "longitude" || field === "address") {
            //  console.log( e.node.data.id)
            that.setState({
                showAddress: true,
                showAddressName: e.node.data.name,
                showAddressId: e.node.data.id,
                showAddressType: 'address',
                showAddressNode: e.node
            });
        }
        if (field === "loading_lat" || field === "loading_lng" || field === "loading_address") {
            //  console.log( e.node.data.id)
            that.setState({
                showAddress: true,
                showAddressName: e.node.data.name,
                showAddressId: e.node.data.id,
                showAddressType: 'loading_address',
                showAddressNode: e.node
            });


        }


        // console.log(e);
    }


    getContextMenuItems = (params) => {

        var that = this;
        var result = [


            'copy',

            'paste',
            'export',


        ];

        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        if (permissions.includes('companies.audit')) {
            result.push('separator');
            result.push({
                name: 'Audit',
                checked: true,
                action: function () {

                    console.log(params);
                    that.setState({
                        showAudit: true,
                        showAuditId: params.node.data.id
                    });
                    console.log('Checked Selected');

                },
                icon:
                    '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
            })
        }
        return result;
    };

    getRowNodeId = (data) => {
        return data.id;
    };


    async handleMassSMS(data) {

        data.ids = [];
        this.gridApi.forEachNodeAfterFilter(function (node, index) {

            data.ids.push(node.data.id);
        });
        //console.log(data.ids);
        HttpService.post(UrlService.apiDomain() + 'api/mass_sms/companies', data)
            .then(res => {
                if (res.data == null) alert("Chyba připojení k serveru");
                if (res.data.result == 'failed') {
                    alert(res.data.message);
                } else {
                    console.log('Successfully sent sms');
                    this.setState({showModalSMS: false});

                }

            })

            .catch((error) => {
                //  alert(error);
                console.log(error);
            });


    }

    async handleMassEmail(data) {

        data.ids = [];
        this.gridApi.forEachNodeAfterFilter(function (node, index) {

            data.ids.push(node.data.id);
        });
        console.log(data.files);
        var form_data = new FormData();

        for (var key in data) {
            if (key !== 'files') form_data.append(key, data[key]);
        }

        if (typeof data.files[0] !== 'undefined') form_data.append('file1', data.files[0]);
        if (typeof data.files[1] !== 'undefined') form_data.append('file2', data.files[1]);
        if (typeof data.files[2] !== 'undefined') form_data.append('file3', data.files[2]);
        if (typeof data.files[3] !== 'undefined') form_data.append('file4', data.files[3]);
        if (typeof data.files[4] !== 'undefined') form_data.append('file5', data.files[4]);

        //formData.append('file', fileInput.files[0]);

        HttpService.post(UrlService.apiDomain() + 'api/mass_email/companies', form_data)
            .then(res => {
                if (res.data == null) alert("Chyba připojení k serveru");
                if (res.data.result == 'failed') {
                    alert(res.data.message);
                } else {
                    console.log('Successfully sent email');
                    this.setState({showModalEmail: false});

                }

            })

            .catch((error) => {
                //  alert(error);
                console.log(error);
            });


    }


    async handleAddressChange(data) {

        let new_data = {};
        for (const [key, value] of Object.entries(data)) {
            if (key !== 'rownode') new_data[key] = value;
        }
        HttpService.put(UrlService.apiDomain() + 'api/' + data.address_model_name + '/' + data.id, new_data)
            .then(res => {
                if (res.data == null) alert("Chyba připojení k serveru");
                if (res.data.alert !== '') alert(res.data.alert);
                if (res.data.result == 'failed') {
                    alert(res.data.message);

                } else {
                    console.log('Successfully updated');
                    data.rownode.setData(res.data.entry);
                    this.setState({showAddress: false});
                }
            })
            .catch((error) => {
                alert(error);
                console.log(error);
            });


    }

    async handleAdd(data) {

        if (data.active != 1) data.active = 0;
        HttpService.post(UrlService.apiDomain() + 'api/companies', data)
            .then(res => {
                if (res.data == null) alert("Chyba připojení k serveru");
                if (res.data.result == 'failed' || typeof res.data.errors !== 'undefined') {
                    var msg = res.data.message + "\n";
                    if (typeof res.data.errors !== 'undefined') {
                        for (const [key, value] of Object.entries(res.data.errors)) {
                            msg += value[0] + "\n";
                        }
                    }
                    alert(msg);
                } else {
                    console.log('Successfully updated');
                    this.setState({showModal: false});


                    var res = this.gridApi.applyTransaction({
                        add: res.data.company,
                        addIndex: 0,
                    });

                    /*
                    let r = this.gridApi.updateRowData({
                        add: res.data.company
                    });
                     */
                    this.gridApi.flashCells({rowNodes: [res.add[0]]});

                }

            })

            .catch((error) => {
                //  alert(error);
                console.log(error);
            });


    }


    async deleteRows(data) { 

        confirmAlert({
            title: this.props.t('Confirm to delete'),
            message: this.props.t('Are you sure to delete this item and all other items associated with it?'),
            buttons: [
                {
                    label: this.props.t('Yes'),
                    onClick: () => {
                        console.log(this.gridApi.getSelectedNodes());

                        this.gridApi.getSelectedNodes().forEach((e) => {
                            HttpService.delete(UrlService.apiDomain() + 'api/companies/' + e.data.id)
                                .then(res => {
                                    console.log('Success')
                                    this.gridApi.updateRowData({remove: [e.data]})  // It will update the row
                                })
                                .catch((error) => {
                                    alert(error);
                                });

                        })

                    }
                },
                {
                    label: this.props.t('No'),

                }
            ]
        });


    }

    render() {
        console.log(this.props);

        var permissions = JSON.parse(localStorage.getItem('my_permissions'));
        return (
            <div className="wrapper">
                <Pusher
                    channel="my-channel"
                    event="my-event"
                    onUpdate={(e) => { //console.log(e);
                        /*
                        var rowNode = this.gridApi.getRowNode('53072');

                            rowNode.setDataValue('dic', e.message);
                            */


                        var msg = JSON.parse(e.message);
                        if (msg.action == 'companies_edit' && this.gridApi) {
                            var rowNode = this.gridApi.getRowNode(msg.id);
                            if (typeof rowNode != 'undefined' && typeof rowNode.data != 'undefined' && typeof rowNode.data[msg.field] != 'undefined') {
                                if (rowNode.data[msg.field] != msg.value) {
                                    rowNode.data[msg.field] = msg.value;
                                    var params = {
                                        force: true,
                                        suppressFlash: false,
                                        rowNodes: [rowNode],
                                        columns: [msg.field]
                                    };
                                    this.gridApi.refreshCells(params);
                                }
                            }
                        }

                        /*
                                            if (msg.action=='edit') {
                                                console.log(msg)
                                                let newRowData = this.state.rowdata.slice();
                                                if (typeof newRowData!='undefined') {
                                                    console.log('DDD' + newRowData.find(refData => refData['id'] == msg.id)[msg.field]);
                                                    newRowData.find(refData => refData['id'] == msg.id)[msg.field] = msg.value;
                                                    console.log('DDD' + newRowData.find(refData => refData['id'] == msg.id)[msg.field]);
                                                    this.setState({rowdata: newRowData});
                                                    var params = {
                                                        force: true,
                                                        suppressFlash: false,
                                                        rowNodes: [this.gridApi.getRowNode(msg.id)],
                                                        columns:[msg.field]
                                                    };
                                                    this.gridApi.refreshCells(params);

                                                }
                                            }
                                            */


                    }}
                />
                <Loading
                    title={this.props.t('Loading')}
                    visibility={this.state.showLoading}
                    size="lg"

                >
                    <div className="row justify-content-md-center">
                        <img height="150"
                             src={loadingGif}

                             alt="Loading"
                        />
                    </div>
                </Loading>
                <Layout
					onUpdateYear={(e)=>{ console.log("loading update year", e); this.setState( { Year: e.Year } ) } } 
					onUpdateMonth={(e)=>{ console.log("loading update month", e); this.setState( { Month: e.Month } ) } }
					onUpdateContinent={(e)=>{ console.log("loading update continent", e); this.setState( { Continent: e.Continent } ) } }
					onUpdateCountry={(e)=>{ console.log("loading update country", e); this.setState( { Country: e.Country } ) } }
				>
                    <Content hideTitle={true} title="Companies">

                        <div className="buttons-companies">
                            <div className="btn-group" style={{'marginRight': '9px'}}>
                                {permissions.includes('companies.create') &&
                                    <button type="button"
                                            className="btn btn-success btn-sm"
                                            onClick={() => this.setState({showModal: true})}
                                    ><i className="fa fa-plus"></i>
                                    </button>
                                }
                                {permissions.includes('companies.delete') &&
                                    <button type="button"
                                            className="btn btn-danger btn-sm"
                                            onClick={() => this.deleteRows()}
                                    ><i className="fa fa-trash"></i>
                                    </button>
                                }
                                {permissions.includes('companies.excel') &&
                                    <button type="button"
                                            className="btn btn-primary btn-sm"
                                            onClick={() => this.gridApi.exportDataAsExcel()}
                                    ><i className="fa  fa-file-excel"></i>
                                    </button>
                                }
                            </div>

                            <div className="btn-group" style={{'marginRight': '9px'}}>
                                {permissions.includes('companies.masssms') &&
                                    <button type="button"
                                            className="btn btn-warning btn-sm"
                                            onClick={() => {

                                                let addresses = [];
                                                this.gridApi.forEachNodeAfterFilter(function (node, index) {

                                                    addresses.push(node.data.phone);
                                                });


                                                console.log(this.gridApi.getFilterModel());

                                                this.setState({showModalSMS: true, showModalSMSAddresses: addresses});
                                            }}
                                    ><i className="fa fa-mobile"></i>
                                    </button>
                                }
                                {permissions.includes('companies.massemail') &&
                                    <button type="button"
                                            className="btn btn-warning btn-sm"
                                            onClick={() => {
                                                let addresses = [];
                                                this.gridApi.forEachNodeAfterFilter(function (node, index) {

                                                    addresses.push(node.data.email);
                                                });
                                                this.setState({
                                                    showModalEmail: true,
                                                    showModalEmailAddresses: addresses
                                                });
                                            }
                                            }
                                    ><i className="fa fa-envelope"></i>
                                    </button>
                                }
								
                            </div>

                            <div className="btn-group" style={{'marginRight': '9px'}}>
                                {permissions.includes('companies.import') &&
                                    <div style={{'marginLeft': '20px'}}>
                                        <FileUploadComponent placeholder={this.props.t('Import from Excel')}/>
                                    </div>
                                }
                            </div>
							
							<div style={{display: 'inline-block', float: 'right', marginRight: '20px'}}>
								<AgGridState 
									model = "companies"
									api = { this.gridApi }
									api_column = { this.gridColumnApi }
									ready = { this.state.AgGridReady }
								/>
							</div>
							
							<br clear="all"/>
							
                        </div>
                        <Modal
                            title={this.props.t('Add new')}
                            fff="bar"
                            visibility={this.state.showModal}
                            size="lg"
                            onClose={() => this.setState({showModal: !this.state.showModal})}
                        >
                            <CompanyAdd id={Date.now()} onAdd={(data) => this.handleAdd(data)}/>
                        </Modal>
                        <Modal
                            title={this.props.t('Mass SMS')}
                            visibility={this.state.showModalSMS}
                            size="lg"
                            onClose={() => this.setState({showModalSMS: !this.state.showModalSMS})}
                        >
                            <MassSMS tmp={Date.now()} addresses={this.state.showModalSMSAddresses}
                                     onAdd={(data) => this.handleMassSMS(data)}/>
                        </Modal>
                        <Modal
                            title={this.props.t('Mass Email')}
                            visibility={this.state.showModalEmail}
                            size="lg"
                            onClose={() => this.setState({showModalEmail: !this.state.showModalEmail})}
                        >
                            <MassEmail tmp={Date.now()} addresses={this.state.showModalEmailAddresses}
                                       onAdd={(data) => this.handleMassEmail(data)}/>
                        </Modal>
                        <Modal
                            title={this.props.t('Audit')}

                            visibility={this.state.showAudit}
                            size="lg"
                            onClose={() => this.setState({showAudit: !this.state.showAudit})}
                        >
                            <Audit type={"companies"} tmp={Date.now()} id={this.state.showAuditId} model={"company"}/>
                        </Modal>
                        <Modal
                            title={this.props.t('Contacts')}

                            visibility={this.state.showContact}
                            size="lg"
                            onClose={() => this.setState({showContact: !this.state.showContact})}
                        >
                            <Contact showContact={this.state.showContact} rownode={this.state.showContactNode}
                                     tmp={Date.now()} id={this.state.showContactId} model={"company"}/>
                        </Modal>
                        <Modal
                            title={this.state.showCompanyCardName}

                            visibility={this.state.showCompanyCard}
                            size="lg"
                            onClose={() => this.setState({showCompanyCard: !this.state.showCompanyCard})}
                        >

                            <CompanyCard company_name={this.state.showCompanyCardName}
                                         loading_address={this.state.showCompanyCardLoadingAddress}
                                         show={this.state.showCompanyCard} rownode={this.state.showCompanyCardNode}
                                         tmp={Date.now()} id={this.state.showCompanyCardId} model={"company"}/>

                        </Modal>
                        <Modal
                            title={this.state.showAddressName}

                            visibility={this.state.showAddress}
                            size="lg"
                            onClose={() => this.setState({showAddress: !this.state.showAddress})}
                        >
                            <Address onAdd={(data) => this.handleAddressChange(data)} show={this.state.showAddress}
                                     rownode={this.state.showAddressNode} tmp={Date.now()} id={this.state.showAddressId}
                                     type={this.state.showAddressType} address_model_name={"companies"}/>
                        </Modal>
                        <div style={{height: '100%', width: '100%'}} id="myGrid" className="ag-theme-alpine">
                            <AgGridReact
                                modules={this.state.modules}
                                rowData={this.state.rowdata}
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                onGridReady={this.onGridReady}
                                onCellValueChanged={this.onCellCValueChanged}
                                onCellClicked={this.onCellClicked}
                                onColumnVisible={this.onColumnsStateChanged.bind(this)}
                                onDragStopped={this.onColumnsStateChanged.bind(this)}
                                onFilterChanged={this.onColumnsStateChanged.bind(this)}
                                frameworkComponents={this.state.frameworkComponents}
                                components={this.state.components}
                                enableCellChangeFlash={true}
                                rowSelection={'multiple'}
                                popupParent={this.state.popupParent}
                                suppressContextMenu={false}
                                pagination={true}
                                paginationPageSize={1000}
                                localeText={this.state.localeText}
                                getRowNodeId={this.getRowNodeId}
                                getContextMenuItems={this.getContextMenuItems}
                                suppressRowClickSelection={true}
								suppressColumnVirtualisation={true}
								onRowDataChanged ={ ( ) => { this.gridColumnApi.autoSizeAllColumns( false ); } }
                            />
                        </div>
                    </Content>
                </Layout>
            </div>
        );
    }
}

Companies.propTypes = {
    t: PropTypes.func.isRequired,
};
export default translate()(Companies);

