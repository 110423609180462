import { Component } from "react";
import Select from 'react-select';

import { translate } from 'react-polyglot';

import PropTypes from 'prop-types';

import ModelController from "../../../model/ModelController";


interface Props {
    onAdd: (data) => void;
};


class AgGridState extends Component< Props > {
	
	#ModelControllerRole = null;
	#ModelControllerState = null;

    constructor( props ) {
		
        super( props );
		
		this.#ModelControllerRole = new ModelController( 
			"ag_grid_roles"
		);
		
		this.#ModelControllerState = new ModelController( 
			"ag_grid_states"
		);
		
		let RoleDefault = { value: 0, label: "<...>" };

        this.state = {
			Roles: [ ],
			RolesOptions: [ RoleDefault ],
			RoleCurrent: RoleDefault,
			RoleName: "",
			
			BlockRead: true,
			BlockUpdate: true,
			BlockDelete: true,
			BlockCreate: true,
			InProcessSet: true,
			InProcessDate: ( new Date( ) ).getTime( ),
			State: null
        };

    }
	
	componentDidMount( ){
		
		console.log( "AgGridState", "componentDidMount" );
		
	}

    componentDidUpdate( prevProps, prevState ) {
		
		console.log( "AgGridState", "componentDidUpdate" );

		if( 
			( prevProps.ready === false ) && 
			( this.props.ready === true )
		){
			
			this.RolesReadAll( );
			
			this.props.api.addGlobalListener( function( type, event ) {
				
				console.log( type, event );
				
				if( 
					( 
						( type.indexOf( "column" ) !== -1 ) && 
						( type.indexOf( "columnHover" ) === -1 ) 
					) || 
					( type === "displayedRowsChanged" ) 
				){
					
					if( this.state.InProcessSet === true ){
					
						if( type === "columnResized" ){
							
							this.setState( {
								InProcessSet: false,
								BlockUpdate: false,
								InProcessDate: ( new Date( ) ).getTime( )
							} );
							
						} else if( type === "columnMoved" ){
							
							this.setState( {
								InProcessSet: false,
								BlockUpdate: false,
								InProcessDate: ( new Date( ) ).getTime( )
							} );
							
						} else if( type === "columnVisible" ){
							
							this.setState( {
								InProcessSet: false,
								BlockUpdate: false,
								InProcessDate: ( new Date( ) ).getTime( )
							} );
							
						}
						
					} else {
						
						if( this.state.RoleCurrent.value != 0 ){
						
							this.setState( {
								BlockUpdate: false
							} );
						
						}
						
					}
					
				}
				
			}.bind( this ) );
			
		}
		
		if( this.state.RoleCurrent !== prevState.RoleCurrent ){
			
			this.StateReadOne( );
			
		}
		
		console.log( this.state.InProcessSet, prevState.InProcessSet, this.state.InProcessDate, prevState.InProcessDate );
		
		if( 
			( this.state.InProcessSet !== prevState.InProcessSet ) &&
			( this.state.InProcessDate !== prevState.InProcessDate )
		){
			
			console.log( "APPLYCOLUMNS" );
			
			if( this.state.InProcessSet === true ){ //TODO
			
				console.log( "APPLYCOLUMNS2" );
				
				if( this.state.State ){
				
					this.props.api_column.applyColumnState( { state: this.state.State, applyOrder: true } );
				
				} else {
					
					this.props.api_column.resetColumnState( );
					
				}
				
			}
			
		}
        
    }
	
	StateGet( ){
		
		return this.props.api_column.getColumnState( );
		
	}
	
	StateSet( State ){
		
		this.setState( { //TODO
			State: State,
			InProcessSet: true,
			InProcessDate: ( new Date( ) ).getTime( )
		} );
		
		//this.props.api_column.applyColumnState( { state: State, applyOrder: true } );
		
	}
	
	StateClear( ){
		
		this.setState( { //TODO
			State: null,
			InProcessSet: true,
			InProcessDate: ( new Date( ) ).getTime( )
		} );
		
		//this.props.api_column.resetColumnState( );
		
	}
	
	SelectChange( Evt ){
		
		console.log( "SelectChange", Evt );
		
		this.setState( {
			InProcessSet: false,
			RoleCurrent: Evt,
			BlockCreate: true,
			BlockUpdate: true,
			RoleName: ""
		} );
		
	}
	
	SelectInputChange( Evt, Params ){
		
		console.log( Evt, Params );
		
		if ( Params.action !== 'input-change' ) {
			
			return;
			
		}
		
		if( Evt !== '' ){
		
			let Roles = this.state.Roles;
			
			let Found = false;
			
			for(
				let Iterator = 0;
				Iterator < Roles.length;
				Iterator++
			){
				
				console.log( Roles[ Iterator ], Evt );
				
				if( Roles[ Iterator ].role_name === Evt ){
					
					Found = true;
					
					break;
					
				}
				
			}
			
			if( Found === true ){
				
				this.setState( {
					RoleName: Evt,
					BlockCreate: true,
					BlockUpdate: true,
					BlockDelete: true
				} );
				
			} else {
				
				this.setState( {
					RoleName: Evt,
					BlockCreate: false,
					BlockUpdate: true,
					BlockDelete: true
				} );
				
			}
		
		} else {
			
			this.setState( {
				RoleName: Evt,
				BlockCreate: true,
				BlockUpdate: true,
				BlockDelete: true
			} );
			
		}
		
	}
		
	RoleCreate(
		
	){
		
		this.setState( {
			BlockCreate: true,
			BlockRead: true,
			BlockDelete: true,
			BlockUpdate: true
		} );
	
		this.#ModelControllerRole.CreateOrUpdate( 
		
			{
				"role_name": this.state.RoleName
			},
			
			function( Role ){
				
				let Roles = this.state.Roles;
				
				let RoleExists = false;
				
				for( 
					let Iterator = 0;
					Iterator < Roles.length;
					Iterator++
				){
					
					if( 
						Role.id === Roles[ Iterator ].id
					){
						
						Roles[ Iterator ] = Role;
						
						RoleExists = true;
					
						break;
					
					}
					
				}
				
				if( RoleExists === false ){
				
					Roles.push( Role );
				
				}
				
				let RoleOption = {
							
					value: Role.id,
					label: Role.role_name
					
				};
				
				let RolesOptions = this.state.RolesOptions;
				
				if( RoleExists === false ){
				
					RolesOptions.push( RoleOption );
						
				}
				
				this.setState( {
					RoleName: "",
					RoleCurrent: RoleOption,
					Roles: Roles,
					RolesOptions: RolesOptions,
					BlockRead: false,
					BlockDelete: false
				} );
				
			}.bind( this ),
			
			function( ErrorMessage ) {
	
				alert( ErrorMessage );
				
				this.setState( {
					BlockRead: false,
					BlockDelete: false
				} );
		
			}.bind( this )
			
		);
		
	}
	
	RolesReadAll(
	
	){
		
		this.setState( {
			BlockCreate: true,
			BlockRead: true,
			BlockDelete: true,
			BlockUpdate: true
		} );
		
		this.#ModelControllerRole.ReadAll(
		
			function( Data ){
				
				let RoleLastId = localStorage.getItem( "ag_grid_role_last" );
				
				let RoleCurrent = this.state.RoleCurrent;
				
				let Roles = this.state.Roles;
				
				let RolesOptions = this.state.RolesOptions;
			
				for( 
					let Iterator = 0;
					Iterator < Data.length;
					Iterator++
				){
					
					Roles.push( Data[ Iterator ] );
					
					let RolesOption = {
						
						value: Data[ Iterator ].id,
						label: Data[ Iterator ].role_name
						
					};
					
					RolesOptions.push( RolesOption );
					
					if( RoleLastId ){
					
						if( RoleLastId.toString( ) === Data[ Iterator ].id.toString( ) ){
							
							RoleCurrent = RolesOption;
							
						}
					
					}
					
				}
				
				
				this.setState( {
					BlockRead: false,
					Roles: Roles,
					RolesOptions: RolesOptions
				} );
				
				this.SelectChange( RoleCurrent );
				
			}.bind( this ),
			
			function( ErrorMessage ) {
	
				alert( ErrorMessage );
				
				this.setState( {
					BlockRead: false
				} );
		
			}.bind( this )
			
		);
		
	}
	
	StateReadOne(
		
	){
		
		let RoleCurrent = this.state.RoleCurrent;
		
		if( RoleCurrent === null ){
			
			localStorage.setItem( "ag_grid_role_last", 0 );
			
			return;
			
		}
		
		if( RoleCurrent.value === 0 ){
			
			localStorage.setItem( "ag_grid_role_last", RoleCurrent.value );
			
			this.setState( {
				BlockCreate: true,
				BlockRead: false,
				BlockDelete: true,
				BlockUpdate: true
			} );
			
			this.StateClear( );
			
			return;
			
		}
		
		localStorage.setItem( "ag_grid_role_last", RoleCurrent.value );
		
		this.setState( {
			BlockCreate: true,
			BlockRead: true,
			BlockDelete: true,
			BlockUpdate: true
		} );
		
		
		let RoleId = RoleCurrent.value;
		
		this.#ModelControllerState.ReadOne(
			
			RoleId + "/" + this.props.model,
			
			function( State ){
				
				if( State !== null ){
					
					this.StateSet( JSON.parse( State.columns ) );
					
				} else {
					
					this.StateClear( );
					
				}
				
				this.setState( {
					BlockRead: false,
					BlockDelete: false
				} );
				
			}.bind( this ),
			
			function( ErrorMessage ) {
	
				alert( ErrorMessage );
				
				this.setState( {
					BlockRead: false
				} );
		
			}.bind( this )
			
		);
		
	}
	
	StateSave(
		
	){
		
		this.setState( {
			BlockCreate: true,
			BlockRead: true,
			BlockUpdate: true,
			BlockDelete: true
		} );
		
		let RoleCurrent = this.state.RoleCurrent;
		
		
		if( RoleCurrent === null ){
			
			return;
			
		}
		
		let RoleId = RoleCurrent.value;
		
		let Columns = this.StateGet( );
		
		
		this.#ModelControllerState.CreateOrUpdate(
		
			{ 
				role_id: RoleId,
				model_name: this.props.model,
				columns: JSON.stringify( Columns ),
			},
			
			function( State ){
				
				console.log( State );
				
				this.setState( {
					BlockRead: false,
					BlockDelete: false
				} );
				
			}.bind( this ),
			
			function( ErrorMessage ) {
	
				alert( ErrorMessage );
				
				this.setState( {
					BlockRead: false,
					BlockDelete: false
				} );
		
			}.bind( this )
			
		);
		
	}
	
	RoleDelete(
	
	){
		
		let RoleCurrent = this.state.RoleCurrent;
		
		
		if( RoleCurrent === null ){
			
			return;
			
		}
		
		let RoleId = RoleCurrent.value;
		
		
		this.setState( {
			BlockCreate: true,
			BlockRead: true,
			BlockUpdate: true,
			BlockDelete: true
		} );
		
		
		this.#ModelControllerRole.Delete( 
		
			RoleId,
			
			function( Role ){
				
				let Roles = this.state.Roles;
				
				for( 
					let Iterator = 0;
					Iterator < Roles.length;
					Iterator++
				){
					
					if( 
						RoleId === Roles[ Iterator ].id
					){
						
						Roles.splice( Iterator, 1 );
					
						break;
					
					}
					
				}
				
				let RolesOptions = this.state.RolesOptions;
				
				for( 
					let Iterator = 0;
					Iterator < RolesOptions.length;
					Iterator++
				){
					
					if( 
						RolesOptions[ Iterator ].value === RoleId
					){
						
						RolesOptions.splice( Iterator, 1 );
					
						break;
					
					}
					
				}
						
				
				this.setState( {
					RoleCurrent: null,
					Roles: Roles,
					RolesOptions: RolesOptions,
					BlockRead: false
				} );
				
				this.StateClear( );
				
			}.bind( this ),
			
			function( ErrorMessage ) {
	
				alert( ErrorMessage );
				
				this.setState( {
					BlockRead: false
				} );
		
			}.bind( this )
			
		);
		
	}

	render( ) {
		
		let Translate = this.props.t;
		
        let permissions = JSON.parse( localStorage.getItem( "my_permissions" ) );

		return (
			<>
				{ permissions.includes( "ag_grid_states.view" ) &&
				<div style = { { width: "360px", height: "50px", margin: "0 auto", overflow: "hidden", whiteSpace: "nowrap" } } >
				
					<div style = { { display: "inline-block", width: "100%", height: "80%", marginLeft: "0", transition: "margin-left 0.1s linear" } } >
					
						<div style = { { display: "inline-block", width: "200px", height: "80%", margin: "3px" } }>
						
							<Select 
								key = { "aggrid_state" }
								name = "aggrid_state"
								onChange = { this.SelectChange.bind( this ) }
								value = { this.state.RoleCurrent }
								placeholder = { Translate( "Select" ) }
								options = { this.state.RolesOptions }
								isDisabled = { this.state.BlockRead }
								menuPortalTarget={ document.body }
								menuPosition={ 'fixed' } 
								onInputChange={ this.SelectInputChange.bind( this ) }
								inputValue = { this.state.RoleName }
							/>
							
						</div>
						
						
						{ permissions.includes( "ag_grid_states.create" ) && //TODO Rights
						<div style = { { display: "inline-block", height: "100%", margin: "3px" } }>
						
							<button
								style = { { fontWeight: "bold", width: "32px", height: "32px", padding: "0px" } }
								className = { "btn btn-primary btn-sm" }
								onClick = { this.RoleCreate.bind( this ) }
								disabled = { ( this.state.RoleName.length === 0 ) || ( this.state.BlockCreate === true ) }
							>
								<i className = { "fa fa-plus" }></i>
							</button>
						
						</div>
						}
						
						{ permissions.includes( "ag_grid_states.edit" ) &&
						<div style = { { display: "inline-block", height: "100%", margin: "3px" } }>
						
							<button
								style = { { fontWeight: "bold", width: "32px", height: "32px", padding: "0px" } }
								className = { "btn btn-success btn-sm" }
								onClick = { this.StateSave.bind( this ) }
								disabled = { ( this.state.RoleCurrent === null ) || ( this.state.BlockUpdate === true ) || ( this.state.InProcessSet === true ) }
							>
								<i className = { "fa fa-save" }></i>
							</button>
							
						</div>
						}
						
						{ permissions.includes( "ag_grid_states.delete" ) &&
						<div style = { { display: "inline-block", height: "100%", margin: "3px" } }>
						
							<button
								style = { { fontWeight: "bold", width: "32px", height: "32px", padding: "0px" } }
								className = { "btn btn-danger btn-sm" }
								onClick = { this.RoleDelete.bind( this ) }
								disabled = { ( this.state.RoleCurrent === null ) || ( this.state.BlockDelete === true ) }
							>
								<i className = { "fa fa-trash" }></i>
							</button>
							
						</div>
						}
						
						<div style = { { display: "inline-block", height: "100%", margin: "3px" } }>
						
							<button
								style = { { fontWeight: "bold", width: "32px", height: "32px", padding: "0px" } }
								className = { "btn btn-primary btn-sm" }
								onClick = { this.StateClear.bind( this ) }
								disabled = { this.state.BlockDelete === true }
							>
								{ "⌫" }
							</button>
							
						</div>
						
					</div>
					
				</div>
				}
			</>
		);
	}
	
};


AgGridState.propTypes = {
    t: PropTypes.func.isRequired,
};


export default translate( )( AgGridState );

